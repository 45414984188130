/* .modalstyle .modalheader {
    border-bottom: none;
} */

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--bgcolor);
    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 320px;
    max-width: 420px;
    margin: auto;
    border-radius: 32px;
    border: 1px solid rgb(26, 39, 34);
    box-shadow: rgba(14, 14, 44, 0.1) 0px 20px 36px -8px, rgba(0, 0, 0, 0.05) 0px 1px 1px;
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter{
    padding: 20px;
    border-top: 0px;
}
.modalstyle .btnstyle {
    background: var(--themecolor);
    border-radius: 30px;
    color: var(--whitetext);
    font-size: 12px;
    border: 1px solid var(--themecolor);
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
}

.modalstyle .btnstyle:hover {
    background: transparent;
    border: 1px solid var(--themecolor);
    color: #fff;
}
.modalstyle .modalheader .modaltitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}
.modalstyle .modalheader{
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.modalstyle .modalheader .closebtn{
    background: none;
    padding: 0px;
    border: none;
    color: var(--whitetext);
    cursor: pointer;
}
.modalstyle .modalbody {
    /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
    padding:  25px;

}

.modalstyle
{
    padding-right: 8px !important;
}
.modalstyle .headlabel{
    font-size: 16px;
    color: var(--themecolor);
    text-align:center;
    font-weight: 600;
}
.modalstyle .vallabel{
    font-size: 14px;
    color: var(--whitetext);
    text-align:center;
    font-weight: 500;
}
.modalstyle .declabel{
    font-size: 14px;
    color: var(--themecolor);
    text-align:center;
    font-weight: 600;
}
@media screen and (max-width:400px) {

    .modalstyle [class="modal-content"] {
        min-width: 280px;
        max-width: 280px;
    }
   
}





