
  /* .containmax{
    font-family: "Satoshi-Regular" !important;  
  } */
.themetxt{
    color: var(--themecolor) !important;
}
.mainhead{
    font-size: 2rem;
}
.cardtext{
    font-size: 16px !important;
    text-align: left;
}
.socialsection{
    display: flex;
    margin-top: 3%;
}
.socialsection img{
    width: 33px;
    height: 33px;
    aspect-ratio: 3/2;
    object-fit: contain;
}
.socialsection a{
  margin-left: 8px;
}
.socialsection a:nth-child(1){
    margin-left: 0px;
  }
.socialsection a:hover .mediass {
    display: none;
  }
  .socialsection a:hover .mediasshover{
      display: block;
  }
  .socialsection a .mediasshover{
      display: none;
  }
/* landing page */

.provideborder {
    border: 1px solid rgb(8, 8, 8);
    border-radius: 35px;
    background: rgb(8, 8, 8);
    padding: 20px;
    /* height: 340px; */
    /* min-height: 340px; */
    /* height: 100%; */
}
.welcome{
    color: var(--whitetext);
  font-family: "Poppins", sans-serif  !important;

   /* font-family:  Chakra Petch,sans-serif !important; */
    font-weight: 700;
}
.welcome1{
    color: var(--whitetext);
    /* font-family: "Satoshi-Regular";  */
}
.marqueeimg1{
    border: 1px solid var(--themecolor);
    border-radius: 6px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    min-height: 80px;
    object-fit: contain;
}
.marqueeimg{
     /* border: 1px solid var(--themecolor);
     width: 180px;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 16px;
     min-height: 80px;
     object-fit: contain; */

     border: 1px solid var(--themecolor);
     border-radius: 6px;
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 16px 25px;
     min-height: 80px;
     height: 80px;
     object-fit: contain;
     max-width: 90%;
     margin: auto;
}
.colwidth .marqueeimg{
    transition: all .3s ease;
}
.colwidth .marqueeimg:hover{
  transform: translateY(-8px);
  /* filter: drop-shadow(2px 4px 6px #407c4a45); */
}

.blurviolent{      
        background: #407c4a;
        filter: blur(50px);
        height: 90px;
        opacity: .2;
        position: absolute;
        left: 20%;
        top: 10%;
        transform: rotate(178deg);
        width: 150px;
        z-index: -1;
}
.blurviolent1{      
    background: #407c4a;
    filter: blur(50px);
    height: 90px;
    opacity: .2;
    position: absolute;
    right: 0%;
    bottom: 0%;
    transform: rotate(178deg);
    width: 120px;
    z-index: -1;
}
.greenSpan{
    color: rgb(38, 145, 60) !important;
    font-size: 14px;
    font-weight: 500;    
}
.blurviolentlast{
        background: #407c4a;
        filter: blur(38px);
        height: 80px;
        opacity: .3;
        position: absolute;
        right: 11%;
        top: -25%;
        transform: rotate(178deg);
        width: 140px;
        z-index: -1;
}
.blurviolent2{
    background: #407c4a;
    filter: blur(38px);
    height: 80px;
    opacity: .2;
    position: absolute;
    left: 0%;
    top: 10%;
    transform: rotate(178deg);
    width: 130px;
    z-index: -1;
}
.blur1{
        top: 0;
        position: absolute;
        max-width: 26%;
}
.blur2{
    bottom: 0;
    right: 0;
    position: absolute;
    max-width: 26%;
}
.heading{
    max-width: 70%;
    margin: auto;
}
.welcomepara{
    color: var(--whitetext);
    /* font-family: 'Space Grotesk', sans-serif !important; */
  font-family: "Poppins", sans-serif  !important;

    font-size: 15px;
}
.donutback{
    background: #407c4a1A;
    padding: 8px;
    border-radius: 6px;
}
.tradenow{
    background: var(--themecolor);
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 20px;
    padding: 6px 22px;
    margin-top: 10px;
}
.tradenow:hover{
    background: var(--btnhover);
    color: var(--whitetext);
}
.donutexp{
    width:130px;
    height:160px;
    object-fit: contain;
    aspect-ratio: 3/2;
}
.tradeImg{
    width:150px;
    height:160px;
    object-fit: contain;
    aspect-ratio: 3/2;
}
.posrel{
    position: relative;
    z-index: 2;
    /* overflow: hidden; */
}
.donutexp1{
    position: absolute;
    right: -12%;
    max-width: 45%;
}
.donutexp2{
    position: absolute;
    right: -22%;
    max-width: 45%;
    top: 6%;
}
.donutrose{
    background: var(--themecolor);
    padding: 10px 26px;
    border-radius: 16px;
}
.donutrose img{
    margin-top: -35%;
}
.donutrose .rows{
    justify-content: space-between;
    align-items:center;
}
.blackbtn{
    background: #1D242F;
    color: var(--whitetext);
    padding: 6px 24px;
    border-radius: 20px;
}
.blackbtn:hover{
    background: var(--btnhover);
    color: var(--whitetext);
}
.maillist{
    display: flex;
}
.maillist input{
    background: #FF9ED6 !important;
    border: #FF9ED6 !important;
    border-radius: 20px;
    color: var(--whitetext);
}
.maillist input::placeholder{
  color: var(--whitetext);
}
.maillist input:focus{
    box-shadow:0 0 0 0.25rem rgb(13 110 253 / 0%);
    color: var(--whitetext);
}
.partnermarquee .partnerwidth:first-child{
    margin-left: 4%;
}
.partnermarquee {
    display: flex;
    flex-direction: row;
    gap: 50px;
}
.partnerwidth{
    /* border: 1px solid var(--themecolor);
    padding: 16px;
    border-radius: 4px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center; */
}
.coming{
    color: var(--whitetext);
}
.welcomess{
    color: var(--whitetext);
    font-size: 18px;
    text-align: left;
    /* font-family: "Satoshi-Medium";  */
}

/* bitdealtz css */
.greenBtn{
    border: 1px solid rgb(38, 145, 60);
    outline: none;
    background-image: linear-gradient(23deg, rgb(38, 145, 60), rgb(38, 145, 60));
    color: var(--whitetext);
    font-size: 16px;
  font-family: "Poppins", sans-serif  !important;
text-decoration: none;
    padding: 8px 30px;
}
.greenBtn:hover{
    background-image: none;
    background-color: transparent;
    color: rgb(38, 145, 60);
}
.greenPoplarity{
    color: rgb(38, 145, 60);
    font-size: 20px;
  font-family: "Poppins", sans-serif  !important;

}
.popularityLabel{
    color: var(--whitetext);
    font-size: 15px;
  font-family: "Poppins", sans-serif  !important;
    

}
.landingInnerTitle{
    color: var(--whitetext);
  font-family: "Poppins", sans-serif  !important;
line-height: 1.5;
  font-size: 33px;
  font-weight: 700;
}
.greenLinerText{
    color: rgb(38, 145, 60);
    font-size: 20px;
    /* font-weight: 800; */
  font-family: "Poppins", sans-serif  !important;

    letter-spacing: 1px;
    position: relative;
    max-width: max-content;

}
.greenLinerText::after{
    content: '';
    position: absolute;
    top: 50%;
    width: 30px;
    right: -15%;
    /* left: 45%; */
    height: 2px;
    background-color: rgb(38, 145, 60);

}
.secondaryBtn{
    border: 1px solid rgb(38, 145, 60);
    background-color: transparent;
    color: rgb(38, 145, 60);
  font-weight: 700;


  font-size: 16px;
    font-family: "Poppins", sans-serif !important;
    text-decoration: none;
    padding: 8px 30px;


}
.secondaryBtn:hover{
    background-color: rgb(38, 145, 60);
    color: var(--whitetext);
}
.exchangeBox{
    background-color: rgb(23, 23, 27);
    border-radius: 36px;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(68, 188, 89) rgb(23, 23, 27) rgb(23, 23, 27);
    border-image: initial;
}
/* end of bitdealtz css */

@media screen and (min-width: 120px) {
    .donutrose{
        padding: 30px 26px;
    }
  
}
@media screen and (max-width: 1199px) {
    .cardBortitle 
    {
        font-size: 15px !important;
    }
    .donutrose{
        padding: 30px 26px;
    }
    .containmax{
        max-width: 90%;
    }
}
@media screen and (max-width: 991px) {
    .revrow
    {
        flex-direction: column-reverse;
    }
    .heading1{
        font-size: 29px;
    }
    .donutrose{
        padding: 30px 26px;
    }
    .marqueeimg{
        min-height: 70px;
        height: 70px;
        max-width: 95%;
    }
}


@media screen and (min-width:768px)
{
    .colwidth
    {
        width: 25% !important;
    }
}


@media screen and (max-width: 767px) {
.imgsecafter::after {
content: none !important;
}
    .greenLinerText
    {
        font-size: 17px !important;
    }
    .provideborder
    {
        flex-direction: column;
    }
    .provideborder img
    {
        margin-top: 20px;
    }
    .landingInnerTitle,.whitetextlarge
    {
        font-size: 25px !important;
    }
    .donutrosepadding
    {
        padding-top: 45px !important;
    }
    .donutrose .rows{
        flex-wrap: wrap-reverse;
    }
   .donutexp1,.donutexp2{
    display: none;
   }
   .heading{
    max-width: 100%;
    margin: auto;
}
.welcome{
    font-size: 30px;
}
.partnerwidth{
    width: 170px;
}
/* .donutrose img{
    margin-top: 15%;
} */
.donutrose{
    padding: 20px 20px;
}
.marqueeimg{
    min-height: 65px;
    height: 65px;
    max-width: 90%;
}
  }


  @media screen and (min-width:576px) and (max-width:767px)
  {
    .donutrose img{
        margin-top: -30%;
    }
  }

@media screen and (max-width: 575px) {
    .greenLinerText::after
    {
        content: none !important;
    }
  /* .welcome{
    font-size: 23px;
} */
.marqueeimg{
    min-height: 56px;
    height: 56px;
    max-width: 100%;
}
.coming{
   font-size: 14px;
   margin-bottom: 0px;
}
  }

  /* @media screen and (max-width: 340px) {
    .welcome{
      font-size: 21px;
  }
    } */

    @media screen and (min-width: 1200px) {
    .donutrose{
        width: 80%;
        margin: auto;
    }
    /* .welcome{
        font-size: 45px;
    } */
    .donutrose .welcome{
        font-size: 40px;
    }
}
/* landing page end */
@media only screen and (min-width:1200px)
{
    .rowmin
    {
        /* max-width: 80%; */
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (min-width:1200px) and (max-width:1399px)

{
.donutrosepadding
{
    padding-top: 100px !important;
}
}


@media only screen and (min-width:1400px)

{
.donutrosepadding
{
    padding-top: 150px !important;
}
.donutrose img{
    margin-top: -130px !important;
}
}
@media only screen and (min-width:992px) and (max-width:1199px)
{
    .rowmin
    {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .donutrose{
        width: 90%;
        margin: auto;
    }
}

.posrelbg
{
    position: relative;
}
.posrelbg::before
{
    content: "";
    position: absolute;
    bottom: -100px;
    left: calc(50% - 75px);
    width: 150px;
    height: 150px;
    background-image: url("../images/bitdealz/doodle.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.provideborder:hover {
    border: 1px solid rgb(68, 188, 89);
}
.bluryel
{
    position: relative;
}
.bluryel::before
{
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    background: linear-gradient(rgba(38, 145, 60, 0.65) 0%, rgba(184, 112, 3, 0.78) 100%) 0% 0% no-repeat padding-box padding-box transparent;
    opacity: 0.47;
    filter: blur(50px);
    right: 0px;
    top: 0px;
}


.bluryel::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: calc(50% - 275px);
    width: 100px;
    height: 100px;
    background-image: url("../images/bitdealz/doodle_1.png");
    background-size: contain;
    background-repeat: no-repeat;
}
.cardcrypto
{
    background-color: transparent;
    border-radius: 36px;
    border:none;
}

.whitetextlarge {
    color: rgb(255, 255, 255);
    font-size: 35px;
    font-weight: 700;
    margin-top: 20px;
    line-height: 50px;
    text-align: left;
}
.bannerUlhome
{
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 20px !important;
    padding-left: 0px !important;
}
.bannerUlhome li {
    list-style-type: none;
    position: relative;
    display: flex;
    margin-bottom: 10px;
}
.bannerUlhome li::before {
    content: " ";
    position: absolute;
    left: 0px;
    top: 6px;
    width: 10px;
    height: 10px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
}
.bannerUlhome li span {
    margin-left: 20px;
}
.imgsecafter
{
    position: relative;
}

.imgsecafter::after {
    content: "";
    position: absolute;
    bottom: -40px;
    right: -50px;
    width: 100px;
    height: 100px;
    background-image: url("../images/bitdealz/doodle.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.bannersecsixth
{
    position: relative;
}
.bannersecsixth::after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: -30px;
    width: 150px;
    height: 150px;
    background-image: url("../images/bitdealz/doodle.png");
    background-size: contain;
    background-repeat: no-repeat;
}
.imgsecaftsev
{
    position: relative;
}
.imgsecaftsev::after {
    content: "";
    position: absolute;
    bottom: -55px;
    right: 60px;
    width: 70px;
    height: 80px;
    background-image: url("../images/bitdealz/doodle_2.png");
    background-size: contain;
    background-repeat: no-repeat;
}
.greenLinerTextsm::after
{
    right: -35% !important;
}
.cardBortitle {
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    font-weight: 500;
}
.cardBordesc
{
    color: rgb(68, 188, 89);
    font-size: 25px;
    text-align: center;
    font-weight: 600;
}
.cardborder {
    background-color: transparent;
    border-radius: 35px;
    border: 1px solid rgb(112, 112, 112);
    cursor: pointer;
}
.cardborder:hover {
    background-color: rgb(68, 188, 89);
    border-color: rgb(68, 188, 89);
}
.cardborder:hover .cardBordesc {
    color: rgb(255, 255, 255);
}
/* #partner_5 .marqueeimg
{
    padding: 25px 35px !important;
} */


/* .colwidth a
{
    border: 1px solid var(--themecolor);
    border-radius: 6px;
    display: inline-block;
}

.colwidth a img
{
    border: none;
    border-radius: 0px;
} */