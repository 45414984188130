/* .modalstyle .modalheader {
    border-bottom: none;
} */

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--bgcolor);
    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 320px;
    max-width: 320px;
    margin: auto;
    border-radius: 32px;
    border: 1px solid rgb(26, 39, 34);
    box-shadow: rgba(14, 14, 44, 0.1) 0px 20px 36px -8px, rgba(0, 0, 0, 0.05) 0px 1px 1px;
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter {
    padding: 20px;
    border-top: 0px;
}

.modalstyle .btnstyle {
    background: var(--themecolor);
    border-radius: 30px;
    color: var(--whitetext);
    font-size: 12px;
    border: 1px solid var(--themecolor);
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
  font-family: "Poppins", sans-serif  !important;

}

.modalstyle .btnstyle:hover {
    background: transparent;
    border: 1px solid var(--themecolor);
    color: #fff;
}

.modalstyle .modalheader .modaltitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}

.modalstyle .modalheader {
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.modalstyle .modalheader .closebtn {
    background: none;
    padding: 0px;
    border: none;
    color: var(--whitetext);
}

.modalstyle .modalbody {
    padding: 20px 25px;

}

.modalstyle .modalbody .tabsec {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.modalstyle .modalbody .tabsec .tabbtn {
    background: var(--bgcolor);
    color: var(--whitetext);
    width: 130px;
    font-size: 14px;
}

.modalstyle .modalbody .tabsec .tabbtn.active {
    background: var(--themecolor);
}

.modalstyle .modalbody .tabsec .tabbtn:hover {
    background: var(--themecolor);
}
.modalbody .tabinnersec .copyinput input{
    background: var(--themecolor);
    border-radius: 15px;
    border: none;
    color: var(--whitetext);
    height: 50px;
}
.modalbody .tabinnersec .copyinput input:focus{
    box-shadow: none;
}
.modalbody .tabinnersec {
    padding-top: 20px;
}
.modalbody .tabinnersec .copyinput span{
    background: var(--themecolor);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border: none;
    height: 50px;
    cursor: pointer;
}
.modalbody .tabinnersec  .label{
    color: var(--whitetext);
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
}
.modalbody .tabinnersec .balrow{
    display: flex;
    justify-content: space-between;

}
.modalbody .tabinnersec .balrow {
    color: var(--whitetext);
    font-size: 14px;
}
.modalbody .tabinnersec .balrow  a{
    font-weight: 700;
    color: var(--whitetext);
    text-decoration: none;
}
.modalbody .tabinnersec .balrow  a:hover{
    color: var(--themecolor);
}
.modalbody .tabinnersec .balrow .rightside{
    text-align: end;
}
.modalbody .tabinnersec .balrow .rightside p{
    margin-bottom: 4px;
}
.modalbody .tabinnersec .norecent{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
}
.modalbody .tabinnersec .recentlabel{
    color: var(--whitetext);
    text-align: center;
}
@media screen and (max-width:400px) {
    .modalstyle .modalbody .innerheadwhite
{
    font-size: 14px;
}

    .modalstyle [class="modal-content"] {
        min-width: 280px;
        max-width: 280px;
    }
   
}