.headTabs {
  height: 40px;
  background: var(--bgcolor);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--whitetext);
  font-weight: 300;
  font-size: 14px;
  border-top: 1px solid #44bc5978;
    box-shadow: rgba(133, 133, 133, 0.1) 0px -2px 0px -8px inset;
}
.headTabs .tabNames {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 0px;
  height: 100%;
  padding: 0px;
}
.headTabs .tabNames a {
  height: 100%;
  text-decoration: none;
  color: var(--whitetext);
}
.headTabs .tabNames li {
  list-style: none;
  height: 100%;
  display: flex;
  align-items: center;
}
.headTabs .tabNames [class="active"] .tabValues {
  position: relative;
  font-weight: 500;
}
.headTabs .tabNames [class="active"] .tabValues::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  background-color: var(--whitetext);
  bottom: 0px;
  border-radius: 3px;
}
