.aboutbanner {
    background: url("../images/bitdealz/aboutbg.jpg") center center / cover no-repeat scroll;
    min-height: 400px;
    font-size: 0px;
    position: relative;
    margin-bottom: 75px;
}

.aboutbanner::after {
    content: "";
    background: rgba(28, 28, 28, 0.36);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
}
.aboutlogo
{
    background: url("../images/bitdealz/aboutLogo.png") center bottom / 100% no-repeat scroll;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    bottom: -75px;
    left: 0%;
    right: 0px;
    margin: auto;
    max-width: 100%;
    z-index: 1;
}
.aboutTitle
{
    font-size: 30px;
    margin-bottom: 25px;
    text-align: center;
    color:var(--whitetext);
    font-weight: 600;
}
.aboutDesc
{
    color: var(--whitetext);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 16px;
}
.aboutUl
{
    list-style: inside disc;
    padding-left: 0px !important;
}
.aboutUl li
{
    color: var(--whitetext);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 15px;
}
.aboutUl li span {
    padding-left: 15px;
}

.tabCustomSec {
    width: 100%;
    display: flex;
}
.leftSec {
    width: 300px;
    border-right: 1px solid rgb(46, 45, 45);
}
.rightSec {
    width: calc(100% - 300px);
    height: 100%;
  min-height: 100vh;
}
.leftSec .contentSec
{
    padding: 30px 10px;
    position: sticky;
    top: 0px;
}
.sideHeaderActive
{
    color: var(--whitetext);
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    display: block;
    line-height: 25px;
    font-size: 14px;
    text-align: left;
    background-image: linear-gradient(23deg, rgb(38, 145, 60), rgb(38, 145, 60)) !important;
    border: 1px solid rgb(38, 145, 60) !important;
    margin-bottom: 15px;

}
.sideHeader
{
    color: var(--whitetext);
    cursor: pointer;
    padding: 10px;
    font-size: 14px;

    display: block;
    line-height: 25px;
    background-color: transparent !important;
    box-shadow: unset !important;
    border-color: transparent !important;
    text-align: left !important;
    margin-bottom: 15px;
}

.rightSec .contentSec
{
    padding: 30px;
}

.abstractContent .header {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: var(--whitetext);
}
.abstractContent .content {
    font-size: 15px;
    color: var(--whitetext);
    line-height: 34px;
    margin-top: 30px;
}
.getstartedContent .header
{
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: var(--whitetext);
    margin-bottom: 40px;
}
.getstartedContent .contentHeader {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 25px;
    color: rgb(38, 145, 60) !important;
}
.getstartedContent .content
{
    font-size: 15px;
    color: var(--whitetext);
    line-height: 28px;
}
.listContent li {
    font-size: 15px;
    color: var(--whitetext);
    line-height: 28px;
    margin-bottom: 16px;
}
.imgFtr
{
    color:var(--whitetext);
}
.downloadTips li {
    color: var(--whitetext);
    margin-bottom: 10px;
}
.listContent
{
    padding-left: 0px !important;
}
.cnt_txt_bld {
    font-size: 15px;
    color: var(--whitetext);
    line-height: 28px;
}
.ctntextbold
{
    font-size: 15px;
    color: var(--whitetext);
    line-height: 28px;
}

@media only screen and (max-width:991px)
{
    .getstartedContent .header,.abstractContent .header
    {
        font-size: 30px !important;
        margin-top: 20px !important;
    }
    .contentSec
    {
        padding: 15px !important;
    }
    .rightSec
    {
        width: 100% !important;
    padding: 0px !important;
    }
    .leftSec {
        background-color: var(--bgcolor);
        width: 100% !important;
        position: sticky !important;
        top: 0px !important;
        border-right: 0px !important;
        border-bottom: 1px solid rgb(46, 45, 45) !important;
    }
    .tabCustomSec {
        flex-direction: column;
    }
    .leftSec .contentSec {
        display: flex;
        gap: 10px;
        overflow-x: auto;
        padding: 0px !important;
        margin: 15px 15px 0px !important;
    }
    .leftSec .sideHeader,.leftSec .sideHeaderActive {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}


.contentSec::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.contentSec::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
  height: 5px;
  border-radius: 20px;
}

.contentSec::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--themecolor);
}