@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&display=swap');

:root {
  --themecolor: #44bc59;
  --bgcolor: #080808;
  --greytext: #3D4E6B;
  --lighttext: #9D9D9D;
  --whitetext: #ffffff;
  --desctext: #DEDEDE;
  --cardbg: #0e0f10;
  --labelbg: #0e0f10;
  --blacktext: #000000;
  --progressbar: #313846;
  --redtext: #FD2B2B;
  --lightgrey: #859BC0;
  --btnhover:#77d1ff;
  --themehover:#407c4a26;
  --cancel:#da1524;
  --acc_clr:#080808;



}




body {
  margin: 0;
  /* font-family: 'Space Grotesk', sans-serif !important; */
  font-family: "Poppins", sans-serif  !important;
  background: var(--blacktext) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#tablestyle1 .asrt-table-body {
  max-height: 250px;
}

#launchpadtable .asrt-table-head {
  display: block;
  background: var(--cardbg);
  margin-bottom: -15px;
  padding: 20px;

}

#launchpadtable .asrt-table-head .float-right.text-right {
  width: 100%;
  height: 35px;
}

#launchpadtable .asrt-table-head .float-right.text-right .table_filter {
  width: 100% !important;
}

#launchpadtable .asrt-table-head .float-right.text-right .table_filter input {
  width: 100% !important;
  background: var(--bgcolor);
  border: none;
  height: 35px;
  color: var(--whitetext);
  background-image: url('./assests/images/searchicon.png');
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: top 10px right 10px;
}

#launchpadtable .asrt-table-head .float-right.text-right .table_filter input::placeholder {
  color: var(--whitetext);
}

#launchpadtable .asrt-table-head .float-right.text-right .table_filter input:focus {
  box-shadow: none;
  background-image: none;

}

#launchpadtable .asrt-table-body {
  max-height: 650px;

}

#launchpadtable::-webkit-scrollbar-track,
#tablestyle1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

#launchpadtable::-webkit-scrollbar,
#tablestyle1::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
  height: 8px;
  border-radius: 20px;
}

#launchpadtable::-webkit-scrollbar-thumb,
#tablestyle1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--themecolor);
}

.as-react-table {
  background: var(--cardbg);
}

#tablestyle1 .asrt-table-foot,
#launchpadtable .asrt-table-foot {
  display: none
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #000;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: #000;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #ffff;
}

.tooltips{
  background: var(--cardbg);
}
@media screen and (max-width:767px){
  #ingwidth_5
  {
    padding: 20px 30px !important;
  }  
}

@media screen and (min-width:768px){
  #ingwidth_5
  {
    padding: 25px 35px !important;
  }  
}

.btn_trans_icon_table
{
  background-color: transparent;
  border:none;
  color:var(--themecolor);
}
.btn_trans_icon_table:hover
{
  color:var(--whitetext);
}
.btn_trans_status_table
{
  background-color: var(--themecolor);
  color:var(--whitetext);
  border:none;
  padding: 8px 10px;
  line-height: 1;
  pointer-events: none;

}

.btn_trans_status_table.disabled_btn_apply
{
  background-color: var(--bgcolor);
  color:var(--whitetext);
  border:none;
  padding: 8px 10px;
  line-height: 1;
  pointer-events: none;
  opacity: 0.5;
  /* cursor: none; */
}
.tabstyle .nav-tabs
{
border:none;
}

@media only screen and (min-width:1300px)
{
  .container {
    max-width: 1200px !important;
    width: 90% !important;
}
}

.modal-backdrop
{
  background-color: #3b3b3b !important;
}
.modal-header
{
  border-bottom: 1px solid rgb(26, 39, 34) !important;
}